import { UserDTO } from '~/domain/user/dto/UserDTO'
import { getAvatarUrl } from '~/domain/user/services/UserDTOService'

export interface Props {
  user: UserDTO
}

export const useUserItem = (props: Props) => {
  const { user } = props

  return {
    user,
    avatarUrl: getAvatarUrl(user),
  }
}
