import { UserDTO } from '~/domain/user/dto/UserDTO'

export interface Props {
  users: UserDTO[]
  cols?: 2 | 3
}

export const useUserItemList = (props: Props) => {
  const { users, cols = 3 } = props

  return {
    users,
    cols,
  }
}
