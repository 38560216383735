import { FC } from 'react';
import clsx from 'clsx';
import { UserItem } from '~/domain/user/components/UserItem/UserItem';
import { useUserItemList, Props } from './useUserItemList';
import styles from './UserItemList.module.css';

export const UserItemList: FC<Props> = (props) => {
  const { users, cols } = useUserItemList(props);

  return (
    <div
      className={clsx(
        styles.list,
        cols === 2 && styles.cols2,
        cols === 3 && styles.cols3
      )}>

      {users.map((user) => {
        return (
          <div key={user.id}>
            <UserItem user={user} />
          </div>);

      })}
    </div>);

};