import { FC } from 'react';
import { Link } from '@remix-run/react';
import truncate from 'lodash.truncate';
import { Card } from '~/application/components/Card/Card';
import { Avatar } from '~/application/components/Avatar/Avatar';
import { Badge } from '~/application/components/Badge/Badge';
import { useUserItem, Props } from './useUserItem';
import styles from './UserItem.module.css';

export const UserItem: FC<Props> = (props) => {
  const { user, avatarUrl } = useUserItem(props);

  return (
    <Link to={`/users/${user.id}`} className={styles.link}>
      <Card>
        <div className={styles.inner}>
          <div className={styles.avatar}>
            <Avatar src={avatarUrl} size="lg" />
          </div>
          <div className={styles.body}>
            <div className={styles.head}>
              <div className={styles.name}>{user.name}</div>
              {user.isReceiveSponsor &&
              <div className={styles.sponsor}>
                  <Badge>スポンサー募集中</Badge>
                </div>}

            </div>
            <p className={styles.summary}>
              {truncate(user.summary, { length: 120 })}
            </p>
          </div>
        </div>
      </Card>
    </Link>);

};